const locals = {
    RU: {
        pages: {
            ContactList: {
                title: "Контакты",
                menuText: "Контакты",
                olderContacts: "Показать ещё",
                nocontent:
                    "В\u00a0дальнейшем тут появится информация и\u00a0контактные данные подобранных Вам партнеров для\u00a0встреч",
                noBookmarks: "В\u00a0этой вкладке будут отображаться контактные данные и\u00a0информация о\u00a0людях, которых Вы добавили в\u00a0закладки.",
                noInterested: "В\u00a0этой вкладке будут отображаться люди, которые вызвали Ваш интерес и\u00a0с\u00a0которыми Вы хотели бы\u00a0связаться.",
                interested: "Интересные",
                bookmarked: "Закладки",
                removeBookmark: "Удалить из закладок",
            },
            Participants: {
                title: "Участники",
                menuText: "Участники",
                nocontent:
                    "Пока тут никого нет<br/>В\u00a0дальнейшем здесь появится информация и\u00a0контактные данные участников сообщества",
                moreParticipants: "Больше участников"
            },
            Admin: {
                title: "Название сообщества",
                menuText: "Название сообщества",
                description: "Описание сообщества",
                communityCRM: "CRM сообщества",
                analytics: "Аналитика",
                noParticipants: "Пока тут никого нет",
            },
            welcome: {
                title: "Начните общение с\u00a0участниками сообщества",
                description:
                    "Алгоритм подберет для\u00a0вас сообщества согласно вашим интересам и\u00a0запросам, после\u00a0чего отправит вам на\u00a0почту предложение об\u00a0онлайн-встрече 1:1",
                button: "Начать общение",
                about: {
                    mainLink: "Главная",
                    aboutLink: "О\u00a0сервисе",
                    politicsLink: "Политика конфиденциальности",
                    launchLink: "Запустить своё\u00a0комьюнити",
                    contactUs: "Напишите нам",
                    volee: "Это приложение",
                    description1:
                        "\u00a0– комьюнити платформа для создания и\u00a0управления онлайн сообществами.",
                    description2:
                        "Находите новые контакты для\u00a0развития себя и\u00a0своего дела.",
                },
                shortLanding: {
                    iconBlockTitle: "Сообщество поможет вам",
                    howItWorks: "Как это работает",
                    community: "Community"
                }
            },
            Auth: {
                email: "Электронная почта",
                password: "Пароль",
                passwordHint: "Не менее 8 символов",
                repeatedPassword: "Повторите пароль",
                emailPlaceholder: "Введите ваш email",
                passwordPlaceholder: "********",
                successText: "Отлично! 👍👍👍",
                googleButtonText: "Продолжить с Google",
                orContinueWith: "или продолжить с",
                or: "или",
                shortAuthButton: "Присоединиться"
            },
            login: {
                title: "Рады видеть снова",
                subtitle: "Войдите, чтобы продолжить",
                button: "Войти",
                accountQuestion: "У вас нет аккаунта? ",
                link: "Зарегистрироваться",
                successText: "Отлично! 👍👍👍",
                recoveryQuestion: "Забыли пароль? ",
                recovery: "Восстановить",
            },
            Registration: {
                title: "Начнём",
                subtitle: "Создайте свой профиль",
                button: "Зарегистрироваться",
                accountQuestion: "Уже есть аккаунт? ",
                link: "Войти",
                agreePolicy: [
                    "Я принимаю условия ",
                    " и даю своё согласие на обработку моих персональных данных на условиях, определенных ",
                ],
                termsOfUse: "Пользовательского\u00a0соглашения",
                privacyPolicy: "Политикой\u00a0конфиденциальности",
                error: {
                    "Duplicate email": "Эта почта уже зарегистрирована",
                },
            },
            Recovery: {
                title: "Найдём",
                button: "Восстановить",
                accountQuestion: "Вспомнили? ",
                link: "Войти",
                successText: (email) =>
                    `На\u00a0вашу почту ${email} придёт письмо с дальнейшими инструкциями`,
            },
            ConfirmRecovery: {
                title: "Новый пароль",
                button: "Восстановить",
                accountQuestion: "Передумали? ",
                link: "Войти",
                successText: "Отлично! Теперь вы можете залогиниться",
            },
            OneTimeCode: {
                title: "Отправили код на\u00a0почту",
                subtitle: "Укажите его, чтобы подтвердить вход с\u00a0",
                button: "Подтвердить",
                label: "Введите код",
                resendAfter: (time) => `Повторить можно через 00:${time}`,
                resend: "Отправить ещё раз",
                invalidCode: "Неверный код",
                sendCode: "Отправить код",
                changeEmail: "Изменить email",
                changeEmailDescr: "Введите другой email, чтобы получить код",
            },
            MyCommunities: {
                title: "Главная",
                menuText: "Сообщество",
                description:
                    "Здесь вы можете найти новых пратнёров или клиентов, поделиться опытом и обсудить проблемы предпринимательства во времена COVID",
                nocontent:
                    "Обратитесь к комьюнити-менеджеру, чтобы получить ссылку на сообщество",
                nocontentBtn: "Перезагрузить",
                successText: "Ваш новый запрос успешно сохранен",
                successSubtitle: "Вы получите еще больше полезных рекомендаций",
                successButton: "Хорошо",
                changeSearchForText: "Изменить критерии поиска",
                changeSearchForButton: "Сохранить",
                searchForPlaceholder: "Введите свой запрос сообществу",
                lastMeetingsTitle: "Как прошла ваша встреча",
                lastMeetingsScore: "Оценка",
                lastMeetingsNote: "Заметки о встрече (по желанию)",
                lastMeetingsNoMeeting: "Не получилось встретиться",
                lastMeetingsSumbit: "Отправить",
                lastMeetingsPopupTitle: "Спасибо за ваш ответ",
                criteriaTitle: (longVersion) =>
                    `${longVersion ? "Кого вы ищете? " : ""
                    }Чтобы найти релевантных людей для вас и вашего дела, напишите свой запрос сообществу${longVersion ? "\u00a0тут" : ""
                    }`,
                criteriaTip:
                    "The list below will change some time after you change your request",
                members: "участников",
                toggleActiveText: {
                    firstPart: "Вы получите новый контакт во вторник.",
                    secondPart: "Также можно найти нужных людей на странице",
                    link: "Участники",
                    discordSecondPart: "Присоединяйся к нашему",
                    discordLink: "Discord сообществу",
                },
                toggleDisabledText: {
                    firstPart:
                        "Начните получать новые контакты из сообщества на почту",
                    or: " или ",
                },
                recommended: "Рекомендованные контакты",
                recommendationsLoading: "Поиск наиболее релевантных участников...",
                noRecommendations: "Вы посмотрели все рекомендованные контакты. Скоро появятся новые рекомендации",
                noRecommendationsBtn: "Все участники",
                moreContacts: "Больше\u00a0контактов",
                interestedSuccessTitle: "Сообщение отправлено",
                interestedSuccessContent: (likedName) => `Пожалуйста, дождитесь ответа от ${likedName}, чтобы договориться о встрече`,
                notInterestedSuccessTitle: "Спасибо за ваш ответ",
                notInterestedSuccessContent: "Контакт скрыт из рекомендаций",
                outOfCredits: "Кредиты закончились",
                bookmarked: "Добавлено в <a href='/contacts/bookmarks'>закладки</a>",
                unbookmarked: "Удалено из закладок",
                tryAgainIn: (timeLeft) => `Попробуйте снова через ${timeLeft}`,
                recommendedTab: "Рекомендации",
                requestsTab: "Запросы",
                myRequest: "Мой Запрос",
            },
            Onboarding: {
                profileFormTitle: "Анкета участника",
                aboutYouTitle: "О Себе",
                linkedInTitle: "Профиль LinkedIn",
                linkedInDescription: "Укажите ссылку на ваш LinkedIn, чтобы автоматически заполнить данные и упростить процесс регистрации",
                locationTitle: "Местоположение",
                locationForDescription: "Знание местоположения поможет соединить вас с теми, кто действительно имеет значение",
                searchForTitle: "Ваш запрос",
                searchForDescription:
                    "Напишите свой запрос сообществу. Например: “Я ищу IT предпринимателей для обмена опытом”. Ваши рекомендации обновятся соответственно запросу.",
                contactsTitle: "Контакты",
                socialNetworkTitle: "Социальные сети",
                yourPhotoTitle: "Ваша фотография",
                searchForButtonText: "Нет особых пожеланий",
                searchForDefaultText: "Нет особых пожеланий",
                buddy: {
                    survey: "Great start! A couple of questions and we will find the best contact in the community that meets your business needs.",
                    aboutYou:
                        "Tell the community about your business to get support and new opportunities.",
                    searchFor:
                        "It would help us to find the right people for you and your business.",
                    contacts:
                        "Specify social networks so that community members can contact you there.",
                },
            },
            LastStep: {
                activeIndexText: "Последний шаг",
                nextButtonText: "Пропустить",
                stepTitle: "Подтверждение почты",
                resendRecoveryEmail: "Отправить снова",
                resendSuccessText: (minutes, seconds) =>
                    `Электронное письмо с подтверждением почты успешно отправлено. Отправить снова будет возможно через ${minutes}:${seconds}`,
            },
            AccountDeleted: {
                activeIndexText: "Аккаунт удалён",
                nextButtonText: "Продолжить",
                text: (communityName) =>
                    `Ваш аккаунт удалён из ${communityName}, но вы всегда можете присоединиться к комьюнити.`,
            },
            Confirmed: {
                activeIndexText: "Подтвердили",
                nextButtonText: "Перейти",
                stepTitle: "Вы успешно подтвердили почту",
                text: "Поздравляем! Вы успешно подтвердили вашу почту, через 5 секунд произойдёт переход на страницу с вашими сообществами.",
            },
            Event: {
                title: "Сообщество",
                menuText: "Сообщество",
            },
            Notifications: {
                title: "Уведомления",
                menuText: "Уведомления",
            },
            Profile: {
                title: "Профиль",
                menuText: "Профиль",
                editProfile: "Редактировать профиль",
                successText: "Изменения сохранены",
                invitedBy: "Приглашение от ",
                createdAt: "В сообществе с ",
                fillProfileWarning: "Чтобы получать более качественные контакты, заполните ",
                form: {
                    title: "Профиль",
                    firstName: {
                        label: "Имя",
                        placeholder: "",
                    },
                    lastName: {
                        label: "Фамилия",
                        placeholder: "",
                    },
                    phone: {
                        label: "Телефон",
                        placeholder: "+79991234567",
                    },
                    company: {
                        label: "Компания, должность",
                        placeholder: "+79991234567",
                    },
                    about: {
                        label: "О себе",
                        placeholder:
                            "Расскажите о себе, своих компетенциях, навыках, хобби",
                    },
                    lookingFor: {
                        label: "Кого вы ищете?",
                        textareaLabel: "Напишите текст запроса",
                        placeholder: "В поиске специалиста с опытом работы в области...",
                        examples: [
                            "Ищу работу в сфере",
                            "Ищу бизнес-партнёров для реализации",
                            "Ищу единомышленников, увлеченных"
                        ],
                        options: [
                            "Я ищу...",
                            "Помогу с...",
                            "Нужна помощь с...",
                            "Ищу новые знакомства ",
                            "Открыт к любым предложениям ",
                            "Свой вариант"
                        ],
                    },
                    location: {
                        label: "Город",
                        placeholder: "Найти город",
                        question: "Показывать другим участникам",
                        tip: "Участники будут видеть ваш город. Вы\u00a0всегда можете изменить этот параметр в Профиле",
                        autodetectError: "Не удалось определить местоположение. Введите вручную.",
                        autodetectButtonLabel: "Определить",
                    },
                    goal: {
                        label: "Какая у вас цель?",
                    },
                    contact: {
                        placeholder: "Ссылка на профиль, https://...",
                    },
                    email: {
                        label: "Email",
                        placeholder: "example@mail.com",
                    },
                    telegram: {
                        label: "Telegram",
                        placeholder: "@username",
                    },
                    instagram: {
                        label: "Instagram",
                        placeholder: "https://instagram.com/username",
                    },
                    linkedin: {
                        label: "LinkedIn",
                        placeholder: "https://linkedin.com/in/username",
                        tip: "Оставьте пустым, чтобы пропустить этот шаг",
                        profileUrl: "<a href='https://www.linkedin.com/in/edit/contact-info/' target='_blank'>Найти свой Linkedin профиль</a>",
                        errorTip: "Ссылка должна начинаться с 'https://www.linkedin.com/in/' и следом ваше имя пользователя",
                        loadingTitle: "Загружаем LinkedIn профиль",
                        errorTitle: "Не удалось загрузить профиль LinkedIn",
                        errorRetryButton: "Попробовать снова",
                        errorManuallyButton: "Я заполню вручную"
                    },
                    facebook: {
                        label: "Facebook",
                        placeholder: "https://facebook.com/username",
                    },
                    github: {
                        label: "GitHub",
                        placeholder: "https://github.com/username",
                    },
                    twitter: {
                        label: "Twitter",
                        placeholder: "https://twitter.com/username",
                    },
                    slack: {
                        label: "Slack",
                        placeholder: "",
                    },
                    vk: {
                        label: "VK",
                        placeholder: "https://vk.com/username",
                    },
                    calendly: {
                        label: "Calendly",
                        placeholder: "https://calendly.com/username",
                    },
                    socialTip2: (num, socials) =>
                        `Укажите как минимум ${num} из соц. сетей ${socials.join(
                            " или "
                        )}`,
                    unsavedChanges: "Есть несохраненные изменения",
                    additionalSocialTip: "По желанию",
                    connectBot: "Подключить бота",
                    botConnected: "Бот подключен",
                    telegramTip: "Получайте контакты в\u00a0Telegram",
                    linkedInConnection: "Подключение LinkedIn",
                    linkedInConnectionHeader: "Вы будете перенаправлены на страницу сообщества в LinkedIn. После этого, пожалуйста, нажмите кнопку 'Установить\u00a0контакт', чтобы завершить процесс",
                    linkedInConnectionNote: "Обратите внимание, что кнопка 'Установить\u00a0контакт' может находится в двух разных местах (см. фото). Если вы уже выполнили подключение ранее или кнопку не удалось найти, просто пропустите этот шаг.",
                    linkedInConnectionBtn: "Перейти на LinkedIn",
                    avatar: "Изменить фото",
                    removeAvatar: "Удалить фото",
                    removeAvatarConfirmation: "Уверены?",
                    save: "Сохранить",
                    updateAvatarModal: {
                        titleFileUpload: "Загрузите новую фотографию",
                        descriptionFileUpload:
                            "Фотография поможет другим пользователям узнать вас и подтвердить, что вы вошли в свою учетную запись. Мы поддерживаем файлы в формате JPG или PNG.",
                        selectFile: "Загрузить фото",
                        cancel: "Отмена",
                        error: "Ошибка загрузки. Попробуйте загрузить другое фото",
                        titleCropper: "Изменение фото профиля",
                        descriptionCropper:
                            "Выберите область, которая будет показана в профиле. Поверните изображение, чтобы правильно его расположить.",
                        save: "Сохранить",
                        back: "Назад",
                        rotate: "Повернуть",
                    },
                    deleteAvatarModal: {
                        title: "Внимание",
                        description: "Вы уверены, что хотите удалить фото?",
                        delete: "Удалить",
                        cancel: "Отмена",
                    },
                    deleteModal: {
                        title: "Удалить аккаунт?",
                        description:
                            "Это действие необратимо. Подтвердите вводом пароля",
                        delete: "Удалить аккаунт",
                        cancel: "Отмена",
                    },
                    error: {
                        avatar: "Ошибка загрузки. Попробуйте загрузить другое фото",
                        exists: (field) => `Необходимо ввести ${field}`,
                        minMaxChars: (field, min, max) =>
                            `${field} может быть от ${min} до ${max} символов`,
                        maxChars: (max) => `Нужно указать до ${max} символов`,
                        emailNotExists:
                            "К сожалению, что-то пошло не так. Пожалуйста, пройдите авторизацию снова.",
                        contactNotConnectedTitle: (contact) =>
                            `${contact} не подключен`,
                        contactNotConnectedContent: (contact) =>
                            `Перейдите в профиль, чтобы подключить ${contact}`,
                        socialNetworkRequired: (socialNetwork) =>
                            `Необходимо ввести ссылку на профиль ${socialNetwork}`,
                        socialNetworkFormat: (socialNetwork) =>
                            `Неверный формат ссылки на профиль ${socialNetwork}`,
                        socialNetworkExists: (socialNetwork) =>
                            `Этот контакт ${socialNetwork} уже существует`,
                        requiredContacts: (
                            requiredContactsMin,
                            requiredContacts
                        ) =>
                            `Нужно ввести хотя бы ${requiredContactsMin} соц. сеть ${requiredContacts.join(
                                " или "
                            )}`,
                        roleInCommunity: "Не забудьте заполнить обязательные поля *",
                    },
                    hideSocialSettings: "Скрыть настройки соц. сетей",
                    showSocialSettings: "Настройки соц. сетей",
                    hideNotificationSettings: "Скрыть настройки уведомлений",
                    showNotificationSettings: "Настройки уведомлений",
                    notificationTip: "Получайте информацию о подобранных контактах",
                    privacy: {
                        hidePrivacySettings: "Скрыть настройки приватности",
                        showPrivacySettings: "Настройки приватности",
                        tip: "Кто видит мой профиль",
                        options: [
                            {
                                value: "PUBLIC",
                                text: "Все в интернете",
                                tip: () => "По персональной ссылке"
                            },
                            {
                                value: "PLATFORM_ONLY",
                                text: "Зарегистрированные пользователи",
                                tip: (community) => `Участники ${community}`
                            },
                            {
                                value: "PRIVATE",
                                text: "Никто, только я",
                                tip: () => "Профиль приватный и не виден никому"
                            },
                        ]
                    }
                },

            },
            Questions: {
                nocontent:
                    "Обратитесь к комьюнити-менеджеру, чтобы получить ссылку на сообщество",
                nocontentBtn: "Перезагрузить",
                back: "Назад",
                next: "Далее",
            },
            Contacts: {
                title: "Контакты",
                menuText: "Контакты",
            },
            AdminNotifications: {
                messages: {
                    general: {
                        subject: (communityTitle) => `Новое сообщение из ${communityTitle}`,
                        signature: (communityTitle) => `<b>Ваш комьюнити менеджер,<br/>${communityTitle}</b>`
                    },
                    recommend: {
                        subject: (communityTitle) => `Интересные участники в ${communityTitle}`,
                        text: (communityUrl) => `Сегодняшнюю рассылку мы решили посвятить теме: <b>TOPIC</b>.<br/><br/>Рассказываем вам об интересных профилях участников на платформе <a href="${communityUrl}" target="_blank">${communityUrl}</a>, на которые вы можете откликнуться:`,
                        signature: (communityTitle, communityUrl, membersCount) => `Чтобы участвовать в нетворкинге и мероприятиях, используем ссылку <a href="${communityUrl}" target="_blank">${communityUrl}</a>, где уже зарегистрировались ${membersCount} участников🤝<br/><br/><b>Ваш комьюнити менеджер,<br/>${communityTitle}</b>`
                    },
                    individual: {
                        subject: (communityTitle) => `Новое сообщение из ${communityTitle}`,
                        title: () => `Привет, `,
                        signature: (communityTitle) => `<b>Ваш комьюнити менеджер,<br/>${communityTitle}</b>`
                    }
                }
            },
            AdminSettings: {
                menuText: 'Настройки',
            }
        },
        errors: {
            emailFormat: "Неверный формат эл. почты",
            serverError: "Ошибка на стороне сервера",
            repeatedPassword: "Пароли не совпали",
            passwordLength:
                "Длина пароля должна быть не менее 8 и не более 128 символов.",
            code: "Не обнаружен код восстановления из запроса. Пройдите по ссылке ещё раз.",
            policy: "Необходимо согласиться с политикой конфиденциальности",
            1005: "Аккаунт не найден",
            1011: "Неверный логин или пароль",
            1014: "Неверный пароль",
        },
        warnings: {
            emailNotConfirmed: (email, communityName, senderEmail) =>
                `Поздравляем!<br><br>
                Теперь вы можете находить интересных для вас участников сообщества <span>${communityName}</span> и назначать им встречи.<br><br>
                На вашу почту <span>${email}</span> пришло сообщение от <span>${senderEmail || "community@volee.space"
                }</span>.<br><br>
                Осталось всего лишь подтвердить email в письме, которое мы вам уже отправили.
                Если не увидели письмо - проверьте в папке "спам" или "промоакции".`,
        },
        components: {
            loader: {
                text: "Загрузка...",
            },
            logout: {
                alreadyMember: "Уже участник?",
                out: "Выйти",
                in: "Войти",
            },
            participant: {
                matchingDate: "Дата подбора",
                successText: "Изменения сохранены",
                lookingFor: "В поисках",
                about: "О себе",
                tags: "Тэги",
                contacts: "Контакты",
                companyLabel: "Компания, должность",
                noCompany: "Компания не указана",
                noLocation: "Город скрыт",
                relevanceLabel: "Рeлевантность",
                relevanceTooltip:
                    "Уровень релевантности рассчитан на основе ваших интересов и запросов. Если уровень высок, то рекомендуем познакомиться с участником сообщества",
                bottomNoteText:
                    "Поле для Вашей заметки по итогам встречи с партнером. Эта заметка видна только Вам.",
                choiceSaved: "Ваш выбор сохранён",
                whyNotInterested: (fullName) =>
                    `Почему Вам не релевантен ${fullName}?`,
                notInterested: "Не интересен",
                alreadyKnow: "Уже знакомы",
                other: "Другое",
                clickHereIf: "Нажмите, если",
                interested: "Связаться",
                interestedMap: {
                    introMessage: (firstName, lastName) =>
                        `Скоро познакомим вас c ${firstName} ${lastName}`,
                    introInputLabel: "Вашe приветственное сообщение (по желанию)",
                    done: "Готово",
                    cancel: "Отмена",
                },
                introMessageMap: {
                    introMessage: (firstName, lastName) =>
                        `Ваш контакт на этой неделе ${firstName} ${lastName}
                        <p>Чтобы договориться о встрече, напишите партнеру и выберите удобный и доступный способ связи. Мы\u00a0сообщим ему о\u00a0Вашем намерении встретиться</p>`,
                    defaultMessage: (firstName, fromName) =>
                        `Привет, ${firstName}!\n\nЯ ${fromName}, Matching бот считает, что мы можем быть друг другу полезны и предлагает нам познакомиться. Расскажи, какими проектами сейчас занимаешься ты?\n\nВот мои контакты:\n\n`,
                    introInputLabel: "Приветственное сообщение",
                    done: "Отправить",
                    cancel: "Пропустить",
                    introSuccessTitle: "Сообщение отправлено",
                },
                cancel: "Отмена",
                no: "Нет",
                fillProfile: (url) => `Заполните\u00a0<a href='/${url}'>профиль</a>\u00a0для просмотра\u00a0контактов`,
                whyDisabled: "Почему это недоступно?",
                createdAt: "Создан",
                invitedBy: "Приглашение от",
                report: "Пожаловаться",
                messageAlreadySent: "Вы уже отправили Интро этому человеку",
                profileLinkCopied: "Ссылка на профиль скопирована",
                buttonTitles: {
                    connect: "Связаться",
                    message: "Сообщение",
                    bookmark: "Закладки",
                    share: "Поделиться",
                },
                privateProfile: "Этот Профиль Скрыт",
                privateProfileHint: (firstName) =>
                    `Профиль ${firstName} скрыт участником, и связаться напрямую не получится.`,
                seeAllButton: "Смотреть всех участников",
                connectButton: "Я все же хочу связаться",
                connectModalTitle: "Запрос Интро",
                connectModalHint: (firstName, senderEmail) =>
                    `Если вы хотите связаться с ${firstName}, пожалуйста, напишите нам на ${senderEmail}, 
                    и мы постараемся организовать знакомство.`,
                connectModalButton: "Связаться с нами",
                privateProfileChange: (settingsPage) => `Ваш профиль скрыт и виден только вам.
                <a href="${settingsPage}#visibility">Изменить</a>`,
            },
            search: {
                placeholder: "Найти человека или экспертизу",
            },
            question: {
                prompt: (strs, num) =>
                    `Можно выбрать ${num === 1
                        ? "один вариант"
                        : num % 10 === 1 && num % 100 !== 11
                            ? `до ${num} варианта`
                            : `до ${num} вариантов`
                    }`,
                promptInput: "Введите ваш ответ (до 256 символов)",
                promptSelect: "Выберите один вариант",
                promptYearInput: "Выберите год",
                promptInputPlaceholder: "Введите ваш ответ",
                searchPlaceholder: "Искать варианты",
                enterYourOption: "Введите свой вариант",
                nocontent: (hasCustomAnswer) =>
                    "Варианты не найдены" + (hasCustomAnswer ? ".<br/>Уточните ваш запрос или введите свой вариант" : ""),
            },
            note: {
                save: "Сохранить",
                cancel: "Отмена",
            },
            header: {
                donate: "Сказать \"Спасибо\"",
                invite: "Пригласить",
                copy: "Копировать",
                copied: "Скопировано",
                invitationTitle: "Отправьте другу ссылку-приглашение в\u00a0сообщество",
            },
            notifications: {
                notifications: "Уведомления",
                options: "Опции",
                settings: "Настройки",
                selectAll: "Выбрать все",
                deselectAll: "Отменить выбор",
                dismiss: "Удалить",
                new: "Новые",
                previously: "Ранее",
                confirmEmail: "Пожалуйста, подтвердите почту",
                confirmEmailUrl: "Подтвердить",
                fillProfile: "Пожалуйста, продолжите заполнение вашего профиля",
                fillProfileUrl: "Заполнить",
                grantPermissions: "Включите уведомления, чтобы моментально узнавать о\u00a0подобранных\u00a0контактах",
                grantPermissionsUrl: "Включить уведомления",
                grantPermissionsTitle: "Будьте в курсе событий!",
                nocontent: "Пока новых уведомлений нет",
                rateMeeting: "Оценить",
                close: "Закрыть"
            },
            tour: {
                controls: {
                    back: "Назад",
                    close: "Закрыть",
                    last: "Завершить",
                    next: "Далее",
                    open: "Открыть диалог",
                    skip: "Пропустить"
                },
                communityCard: "Добро пожаловать в Сообщество! Давайте быстро покажем вам, как пользоваться системой.",
                searchForInput: "Оставьте запрос на конкретную экспертизу здесь. Наш алгоритм использует Ваш запрос для подбора рекомендаций.",
                settingButton: "Для изменения ответов в анкете используйте эту кнопку. Для дополнительных настроек посетите страницу Профиль.",
                networkButton: "Управляйте участием во встречах. Включите, чтобы еженедельно получать рекомендации контактов.",
                requestsTab: "Узнайте, кого сейчас ищут другие пользователи на вкладке Запросы.",
                interestedButtons: "Нажмите 'Связаться', чтобы мгновенно отправить сообщение или 'X', чтобы скрыть рекомендацию."
            },
            requests: {
                title: "Запросы",
                allRequests: "Все запросы",
                nocontent: "Пока в сообществе нет запросов, соответствующих вашим интересам, но скоро они появятся",
                answerOnRequest: ["Ответить", "на запрос"],
                match: "Совпадение",
                answerDefaultText: (firstName, communityTitle) => `\nПривет ${firstName},\nОтвечаю на твой запрос в ${communityTitle}!\n\nВот как я могу с этим помочь:\n\n`,
            }

        },
        months: [
            "января",
            "февраля",
            "марта",
            "апреля",
            "мая",
            "июня",
            "июля",
            "августа",
            "сентября",
            "октября",
            "ноября",
            "декабря",
        ],
    },
    EN: {
        pages: {
            ContactList: {
                title: "Matches",
                menuText: "My matches",
                olderContacts: "Load more",
                nocontent:
                    "In\u00a0the\u00a0future, information and\u00a0contact details of\u00a0the\u00a0partners you have selected for\u00a0meetings will appear here",
                noBookmarks: "In\u00a0this\u00a0tab, you will find contact information and\u00a0details about\u00a0the\u00a0individuals you\u00a0have\u00a0bookmarked.",
                noInterested: "In\u00a0this\u00a0tab, you will find individuals who have sparked your interest and\u00a0whom you wish to\u00a0connect\u00a0with.",
                interested: "Interested",
                bookmarked: "Bookmarks",
                removeBookmark: "Remove bookmark",
            },
            Participants: {
                title: "Members",
                menuText: "All members",
                nocontent:
                    "In\u00a0the\u00a0future, information and\u00a0contact details of\u00a0the\u00a0partners you have selected for\u00a0meetings will appear here",
                moreParticipants: "More participants"
            },
            Admin: {
                title: "Admin area",
                menuText: "Admin area",
                description: "Description",
                communityCRM: "Community CRM",
                analytics: "Analytics",
                noParticipants: "No participants in community yet",
            },
            welcome: {
                title: "Start networking with\u00a0community members",
                description:
                    "We will find the most relevant contact in\u00a0the\u00a0community respecting your\u00a0interests and requests and send you an\u00a0offer for\u00a0a\u00a01:1 online meeting via\u00a0email or\u00a0messenger",
                button: "Start networking",
                about: {
                    mainLink: "Main",
                    aboutLink: "About service",
                    politicsLink: "Privacy Policy",
                    launchLink: "Launch your\u00a0own community",
                    contactUs: "Contact\u00a0us",
                    volee: "This application",
                    description1:
                        "\u00a0– a\u00a0community platform to\u00a0create and\u00a0manage all community activities and\u00a0data in\u00a0one\u00a0place.",
                    description2:
                        "Find the most relevant contacts for\u00a0their\u00a0promotion, networking or\u00a0business developments.",
                },
                shortLanding: {
                    iconBlockTitle: "The community will help you",
                    howItWorks: "How it works",
                    community: "Community"
                }
            },
            Auth: {
                email: "E-mail",
                password: "Password",
                passwordHint: "At least 8 characters",
                repeatedPassword: "Confirm Password",
                emailPlaceholder: "Enter your email",
                passwordPlaceholder: "********",
                googleButtonText: "Continue with Google",
                orContinueWith: "or continue with",
                or: "or",
                shortAuthButton: "Get started"
            },
            login: {
                title: "Welcome back",
                subtitle: "Log in to continue",
                button: "Sign in",
                accountQuestion: "Don't have an account yet? ",
                link: "Register now",
                recoveryQuestion: "Forgotten password? ",
                recovery: "Reset",
            },
            Registration: {
                title: "Let's get started",
                subtitle: "Create an account",
                button: "Register",
                accountQuestion: "Have an account already? ",
                link: "Login now",
                agreePolicy: ["I agree to the ", " and to the "],
                termsOfUse: "Terms of Use",
                privacyPolicy: "Privacy Policy",
                error: {
                    "Duplicate email": "This email already exists",
                },
            },
            Recovery: {
                title: "Reset password",
                button: "Reset",
                accountQuestion: "Remember? ",
                link: "Sign in",
                successText: (email) =>
                    `We've sent you an email with instructions on how to reset your password to ${email}`,
            },
            ConfirmRecovery: {
                title: "Enter new password",
                button: "Recover",
                accountQuestion: "Changed your mind? ",
                link: "Sign in",
                successText: "Fine! Now You can sign in with the new password",
            },
            OneTimeCode: {
                title: "We have sent the\u00a0code",
                subtitle: "Enter the code to confirm logging in with\u00a0",
                button: "Confirm",
                label: "Code",
                resendAfter: (time) => `Resend in 00:${time}`,
                resend: "Resend code",
                invalidCode: "Invalid code",
                sendCode: "Send code",
                changeEmail: "Change email",
                changeEmailDescr: "Please, enter your email to receive one time code"
            },
            MyCommunities: {
                title: "Main",
                menuText: "Community",
                description:
                    "Here you can find new clients or partners. Share the experience and speak about entrepreneurship challenges in COVID times",
                nocontent:
                    "Please, ask your community manager to provide an invitation link",
                nocontentBtn: "Reload",
                successText: "Your new request saved",
                successSubtitle: "You will get more relevant recommendations",
                successButton: "Got it",
                changeSearchForText: "Change search criteria",
                changeSearchForButton: "Save",
                searchForPlaceholder: "Enter your request to the community",
                lastMeetingsTitle: "How was your recent meeting",
                lastMeetingsScore: "Score",
                lastMeetingsNote: "Meeting notes (optional)",
                lastMeetingsNoMeeting: "I haven't met this person",
                lastMeetingsPopupTitle: "Thanks for your feedback",
                lastMeetingsSumbit: "Submit",
                criteriaTitle: (longVersion) =>
                    "How community can help you? Your request will help us to find the right people for you",
                criteriaTip:
                    "The list below will change some time after you change your request",
                members: "people",
                toggleActiveText: {
                    firstPart: "You will get new contact via email on Tuesday.",
                    secondPart: "Also, you can find relevant contacts on",
                    link: '"All\xa0members"\xa0page.',
                    discordSecondPart: "Join our",
                    discordLink: "Discord community",
                },
                toggleDisabledText: {
                    firstPart:
                        "Start to get new connections in the community via email",
                    or: " or ",
                },
                recommended: "Recommended contacts",
                recommendationsLoading: "Calculating the most relevant contacts for you...",
                noRecommendations: "You've seen all recommended contacts. New recommendations will appear soon",
                noRecommendationsBtn: "All members",
                moreContacts: "More contacts",
                interestedSuccessTitle: "We delivered your message",
                interestedSuccessContent: (likedName) => `Please, wait for the answer from ${likedName} to schedule a meeting`,
                notInterestedSuccessTitle: "Thanks for your feedback",
                notInterestedSuccessContent: "This contact was hidden from your recommendations",
                outOfCredits: "Out of Credits",
                bookmarked: "Added to <a href='/contacts/bookmarks'>bookmarks</a>",
                unbookmarked: "Removed from bookmarks",
                tryAgainIn: (timeLeft) => `Try again in ${timeLeft}`,
                recommendedTab: "Recommended",
                requestsTab: "Requests",
                myRequest: "My Request",
            },
            Onboarding: {
                profileFormTitle: "Participant form",
                aboutYouTitle: "About you",
                linkedInTitle: "Connect LinkedIn",
                linkedInDescription: "Specify LinkedIn profile to auto-fill your details and simplify the onboarding process",
                locationTitle: "Location",
                locationForDescription: "Knowing your location helps us connect you with people who truly matter",
                searchForTitle: "Your request",
                searchForDescription:
                    "Describe the people and expertise you are looking for: position, background, skills, personal qualities, interests. Your recommendations will be updated according to the request.",
                contactsTitle: "Contacts",
                socialNetworkTitle: "Social networks",
                yourPhotoTitle: "Your photo",
                searchForButtonText: "No specific expectations",
                searchForDefaultText: "No specific expectations",
                buddy: {
                    survey: "Great start! A couple of questions first and we'll find the best contacts in the community to match your business needs",
                    aboutYou:
                        "The more you tell the community about yourself, the more relevant connections you'll make",
                    searchFor:
                        "Help us to help you find the right connections for you and your business",
                    contacts:
                        "List your social profiles so that community members can contact you there",
                },
            },
            LastStep: {
                activeIndexText: "Last Step",
                nextButtonText: "Skip",
                stepTitle: "Confirm your email",
                resendRecoveryEmail: "Resend",
                resendSuccessText: (minutes, seconds) =>
                    `Email with confirmation has been sent successfully. You can resend again in ${minutes}:${seconds}`,
            },
            AccountDeleted: {
                activeIndexText: "Account deleted",
                nextButtonText: "Continue",
                text: (communityName) =>
                    `Your account has been deleted from ${communityName}, but you can always join community.`,
            },
            Confirmed: {
                activeIndexText: "Confirmed",
                nextButtonText: "Continue",
                stepTitle: "Your email confirmed",
                text: "Congratulations! You successfully confirmed your email. You will be redirected to your communities page or to login page in\u00a05\u00a0seconds.",
            },
            Event: {
                title: "Community",
                menuText: "Community",
            },
            Notifications: {
                title: "Notifications",
                menuText: "Notifications",
            },
            Profile: {
                title: "Profile",
                menuText: "Profile",
                editProfile: "Edit profile",
                successText: "Changes saved",
                invitedBy: "Invited by ",
                createdAt: "Profile opened ",
                fillProfileWarning: "To receive more relevant contacts, please fill in ",
                form: {
                    title: "Profile",
                    firstName: {
                        label: "First name",
                        placeholder: "",
                    },
                    lastName: {
                        label: "Last name",
                        placeholder: "",
                    },
                    phone: {
                        label: "Phone",
                        placeholder: "+79991234567",
                    },
                    company: {
                        label: "Company, position",
                        placeholder: "+79991234567",
                    },
                    about: {
                        label: "About me",
                        placeholder:
                            "Tell us about yourself, your skills and interests",
                    },
                    lookingFor: {
                        label: "Looking for",
                        textareaLabel: "Enter your request",
                        placeholder:
                            "Seeking an individual with expertise in...",
                        examples: [
                            "Employment in the field of",
                            "Business partners to develop",
                            "Like-minded individuals passionate about"
                        ],
                        options: [
                            "I'm looking for...",
                            "I can help with...",
                            "I need help with...",
                            "I'm looking to meet new people ",
                            "Open to any suggestions ",
                            "Other"
                        ],
                    },
                    location: {
                        label: "City",
                        placeholder: "Search for city",
                        question: "Share my location with others",
                        tip: "Other participants will see your city. You\u00a0can always change this setting in Profile",
                        autodetectError: "Failed to detect location. Please, enter manually.",
                        autodetectButtonLabel: "Locate",
                    },
                    goal: {
                        label: "What is your goal in this community?",
                    },
                    contact: {
                        placeholder: "Link to your profile, https://...",
                    },
                    email: {
                        label: "Email",
                        placeholder: "example@mail.com",
                    },
                    telegram: {
                        label: "Telegram",
                        placeholder: "@username",
                    },
                    instagram: {
                        label: "Instagram",
                        placeholder: "https://instagram.com/username",
                    },
                    linkedin: {
                        label: "LinkedIn",
                        placeholder: "https://linkedin.com/in/username",
                        tip: "Leave the field blank if you wish to skip this step",
                        profileUrl: "<a href='https://www.linkedin.com/in/edit/contact-info/' target='_blank'>Find your Linkedin profile url</a>",
                        errorTip: "The link should start with 'https://www.linkedin.com/in/' followed by your username",
                        loadingTitle: "Loading LinkedIn profile",
                        errorTitle: "Failed to load LinkedIn profile",
                        errorRetryButton: "Try again",
                        errorManuallyButton: "Fill manually"
                    },
                    facebook: {
                        label: "Facebook",
                        placeholder: "https://facebook.com/username",
                    },
                    github: {
                        label: "GitHub",
                        placeholder: "https://github.com/username",
                    },
                    twitter: {
                        label: "Twitter",
                        placeholder: "https://twitter.com/username",
                    },
                    slack: {
                        label: "Slack",
                        placeholder: "",
                    },
                    vk: {
                        label: "VK",
                        placeholder: "https://vk.com/username",
                    },
                    calendly: {
                        label: "Calendly",
                        placeholder: "https://calendly.com/username",
                    },
                    socialTip2: (num, socials) =>
                        `Please, specify at least ${num} of ${socials.join(
                            " or "
                        )} links`,
                    unsavedChanges: "You have unsaved changes",
                    additionalSocialTip: "Additional (optional)",
                    connectBot: "Connect bot",
                    botConnected: "Bot connected",
                    telegramTip: "Be notified about matches via Telegram",
                    linkedInConnection: "LinkedIn Connection",
                    linkedInConnectionHeader: "You are about to be redirected to the LinkedIn community page. Once there, please click on the 'Connect' button to\u00a0complete\u00a0the\u00a0process",
                    linkedInConnectionNote: "Note that the 'Connect' button can be found in two different locations (see images above). If you have completed the connection previously or the 'Connect' button could not be found, just skip this step.",
                    linkedInConnectionBtn: "Proceed to LinkedIn",
                    avatar: "Update photo",
                    removeAvatar: "Delete photo",
                    removeAvatarConfirmation: "Remove?",
                    save: "Save",
                    updateAvatarModal: {
                        titleFileUpload: "Upload new photo",
                        descriptionFileUpload:
                            "A picture helps people to recognise you and lets you know when you’re signed in to your account. We support JPG and PNG files.",
                        selectFile: "Upload photo",
                        cancel: "Cancel",
                        error: "Uploading error. Try to use another photo",
                        titleCropper: "Change profile photo",
                        descriptionCropper:
                            "Please select an area for your profile picture.You can rotate the image to position it properly.",
                        save: "Save and continue",
                        back: "Back",
                        rotate: "Rotate",
                    },
                    deleteAvatarModal: {
                        title: "Warning",
                        description:
                            "Are you sure you want to delete this photo?",
                        delete: "Delete",
                        cancel: "Cancel",
                    },
                    deleteModal: {
                        title: "Delete account?",
                        description:
                            "This action is irreversible. Confirm by entering password",
                        delete: "Delete account",
                        cancel: "Cancel",
                    },
                    error: {
                        avatar: "Uploading error. Try to use another photo",
                        exists: (field) =>
                            `The ${field} section requires an answer`,
                        minMaxChars: (field, min, max) =>
                            `${field} might be between ${min} and ${max} chars`,
                        maxChars: (max) => `Should be less than ${max} chars`,
                        emailNotExists:
                            "Unfortunately, something went wrong. Please reload your page",
                        contactNotConnectedTitle: (contact) =>
                            `${contact} is not connected`,
                        contactNotConnectedContent: (contact) =>
                            `Go to Profile and connect ${contact}`,
                        socialNetworkRequired: (socialNetwork) =>
                            `Required to specify ${socialNetwork} profile link`,
                        socialNetworkFormat: (socialNetwork) =>
                            `Invalid format of ${socialNetwork} profile link`,
                        socialNetworkExists: (socialNetwork) =>
                            `This ${socialNetwork} contact already exists`,
                        requiredContacts: (
                            requiredContactsMin,
                            requiredContacts
                        ) =>
                            `Required to specify at least ${requiredContactsMin} of ${requiredContacts.join(
                                " or "
                            )} links`,
                        roleInCommunity: "Don't forget to complete all required fields *",
                    },
                    hideSocialSettings: "Hide social networks settings",
                    showSocialSettings: "Social networks settings",
                    hideNotificationSettings: "Hide notification settings",
                    showNotificationSettings: "Notification settings",
                    notificationTip: "Receive information about relevant contacts",
                    privacy: {
                        hidePrivacySettings: "Hide privacy settings",
                        showPrivacySettings: "Privacy settings",
                        tip: "Who can see my profile",
                        options: [
                            {
                                value: "PUBLIC",
                                text: "Everyone on the Internet",
                                tip: () => "Accessible via personal link"
                            },
                            {
                                value: "PLATFORM_ONLY",
                                text: "Only registered users",
                                tip: (community) => `Participants of ${community}`
                            },
                            {
                                value: "PRIVATE",
                                text: "Nobody, only me",
                                tip: () => "Profile is private and visible to nobody"
                            },
                        ]
                    }
                }
            },
            Questions: {
                nocontent:
                    "Please, ask your community manager to provide an invitation link",
                nocontentBtn: "Reload",
                back: "Back",
                next: "Next",
            },
            Contacts: {
                title: "Matches",
                menuText: "My matches",
            },
            AdminNotifications: {
                messages: {
                    general: {
                        subject: (communityTitle) => `New notification from ${communityTitle}`,
                        signature: (communityTitle) => `<b>Your community manager,<br/>${communityTitle}</b>`
                    },
                    recommend: {
                        subject: (communityTitle) => `Meet interesting members in the ${communityTitle}`,
                        text: (communityUrl) => `Today, we have decided to dedicate the newsletter to: <b>TOPIC</b>.<br/>Let's connect, unite, and share experiences.<br/><br/>Here are some relevant contacts with whom you might want to engage in conversations about this topic:`,
                        signature: (communityTitle, communityUrl, membersCount) => `Additionally, on our community platform <a href="${communityUrl}" target="_blank">${communityUrl}</a>, there are already <b>${membersCount}</b> participants. You can customize your preferences to discover even more suitable matches based on your personality and requests by connecting with the top three most relevant members on the main page.<br/><br/><b>Your community manager,<br/>${communityTitle}</b>`
                    },
                    individual: {
                        subject: (communityTitle) => `New notification from ${communityTitle}`,
                        title: () => `Hello, `,
                        signature: (communityTitle) => `<b>Your community manager,<br/>${communityTitle}</b>`
                    }
                }
            },
            AdminSettings: {
                menuText: 'Settings',
            }
        },
        errors: {
            emailFormat: "Email is in the wrong format",
            serverError: "Server side error",
            repeatedPassword: "Passwords don't match",
            passwordLength:
                "Length of your password should be between 8 and 128 symbols.",
            code: "There is not a recovery code. Could you please use link from letter again.",
            policy: "Confidentiality agreement required",
            1005: "Account not found",
            1011: "Invalid login or password",
            1014: "Invalid password",
        },
        warnings: {
            emailNotConfirmed: (email, communityName, senderEmail) =>
                `Congratulations, now you are in the <span>${communityName}</span> community!<br><br>
                 We have already sent a message from <span>${senderEmail || "community@volee.space"
                }</span> to your email <span>${email}</span>.<br><br>
                 If the email is in Spam or in Promotions, please add our email to your contact list and mark "Not spam". Otherwise, you may miss new relevant contacts from <span>${senderEmail || "community@volee.space"
                }</span>.`,
        },
        components: {
            loader: {
                text: "Loading...",
            },
            logout: {
                alreadyMember: "Already a member?",
                out: "Logout",
                in: "Log In",
            },
            participant: {
                matchingDate: "Matching date",
                addNote: "Your note",
                save: "Save",
                successText: "Changes saved",
                about: "About",
                lookingFor: "Looking for",
                tags: "Tags",
                contacts: "Contacts",
                companyLabel: "Company, position",
                noCompany: "Company not specified",
                noLocation: "City is hidden",
                relevanceLabel: "Relevance level",
                relevanceTooltip:
                    "The level of common interests, background and goals. If it's high we recommend you to make the meeting with community member",
                bottomNoteText:
                    "Outcomes of the meetings, such as possible topics and offers to the partner. Visible only to you.",
                choiceSaved: "Your choice was saved",
                whyNotInterested: (fullName) =>
                    `Why ${fullName} is not relevant?`,
                notInterested: "Not interested",
                alreadyKnow: "Already know",
                other: "Other",
                clickHereIf: "Click if",
                interested: "Connect",
                interestedMap: {
                    introMessage: (firstName, lastName) =>
                        `We will introduce you to ${firstName} ${lastName}`,
                    introInputLabel: "Write a personal welcome message (optional)",
                    done: "Send",
                    cancel: "Cancel",
                },
                introMessageMap: {
                    introMessage: (firstName, lastName) =>
                        `Your contact for this week ${firstName} ${lastName}
                    <p>Now you can write to your partner directly.
                    Don't be shy to contact your partner first :)</p>`,
                    defaultMessage: (firstName) =>
                        `Hello, ${firstName}!\n\nMatching bot has connected us\nLet's have a call this Wednesday at any desirable time\n\nHere are my contacts:\n\n`,
                    introInputLabel: "Your intro message",
                    done: "Send",
                    cancel: "Skip",
                    introSuccessTitle: "We delivered your message",
                },
                cancel: "Cancel",
                no: "Not",
                fillProfile: (url) => `Complete\u00a0<a href='/${url}'>profile</a> to\u00a0see\u00a0contacts`,
                whyDisabled: "Why is this disabled?",
                createdAt: "Joined",
                invitedBy: "Invited by",
                report: "Report profile",
                messageAlreadySent: "You have already sent an Intro to this person",
                profileLinkCopied: "Profile link copied",
                buttonTitles: {
                    connect: "Connect",
                    message: "Message",
                    bookmark: "Bookmark",
                    share: "Share",
                },
                privateProfile: "This Profile is Private",
                privateProfileHint: (firstName) =>
                    `${firstName} has chosen to limit access to their profile, so no one can connect directly.`,
                seeAllButton: "See all participants",
                connectButton: "I still want to connect",
                connectModalTitle: "Introduction Request",
                connectModalHint: (firstName, senderEmail) =>
                    `If you would like to connect with ${firstName}, please contact us via
                        ${senderEmail} and we will do our best to make an introduction for you and ${firstName}.`,
                connectModalButton: "Contact us",
                privateProfileChange: (settingsPage) => `Your profile is private and visible only to you.
                <a href="${settingsPage}#visibility">Change</a>`
            },
            search: {
                placeholder: "Search for person or expertise",
            },
            question: {
                prompt: (strs, num) =>
                    `You can select ${num === 1 ? "one option" : `from 1 to ${num} options`
                    }`,
                promptInput: "Enter your answer (less than 256 chars)",
                promptSelect: "Select one option",
                promptYearInput: "Select year",
                promptInputPlaceholder: "Enter your answer",
                searchPlaceholder: "Search for options",
                enterYourOption: "Enter your option",
                nocontent: (hasCustomAnswer) =>
                    "No options found" + (hasCustomAnswer ? ".<br/>Please refine your search or enter your own" : ""),
            },
            note: {
                cancel: "Cancel",
                save: "Save",
            },
            header: {
                donate: "Support Us",
                invite: "Invite",
                copy: "Copy",
                copied: "Link copied",
                invitationTitle: "Invite friends to the community with your personal\u00a0link",
            },
            notifications: {
                notifications: "Notifications",
                options: "Options",
                settings: "Settings",
                selectAll: "Select all",
                deselectAll: "Deselect all",
                dismiss: "Delete",
                new: "New",
                previously: "Previously",
                confirmEmail: "Please, confirm your email",
                confirmEmailUrl: "Confirm now",
                fillProfile: "Please, continue filling your profile",
                fillProfileUrl: "Go to Profile",
                grantPermissions: "Enable notifications to instantly connect\u00a0with\u00a0new\u00a0contacts",
                grantPermissionsUrl: "Turn on notifications",
                grantPermissionsTitle: "Stay in the loop!",
                rateMeeting: "Rate meeting",
                nocontent: "Nothing new for now",
                close: "Close",

            },
            tour: {
                controls: {
                    back: "Back",
                    close: "Close",
                    last: "Finish",
                    next: "Next",
                    open: "Open the dialog",
                    skip: "Skip"
                },
                communityCard: "Welcome to the Community! Let's quickly show you around.",
                searchForInput: "Drop your request for specific expertise here. Our algorithm uses it to calculate recommended contacts.",
                settingButton: "Use this to change your onboarding answers. For more settings, check out your Profile page.",
                networkButton: "Manage your meeting participation here. Keep it on to get weekly contact recommendations.",
                requestsTab: "Check out what other people are currently looking for at the Requests tab",
                interestedButtons: "Hit 'Connect' to instantly reach out or 'X' to dismiss a recommendation."
            },
            requests: {
                title: "Requests",
                allRequests: "View all requests",
                nocontent: "There are no requests matching your interests yet, but they will appear soon",
                answerOnRequest: ["Answer", "on request"],
                match: "Match",
                answerDefaultText: (firstName, communityTitle) => `\nHi ${firstName},\nI've found your request in the ${communityTitle}!\n\nThis is how I can help you: \n\n\n`
            }
        },
        months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
    },
};

export default locals;
